import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import axios from 'axios';
import moment from 'moment';
import Transparent from '../images/sprite/transparent.gif';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Loading from "../images/sprite/loading40.gif"

import Slider from 'react-slick';

import livecasino from '../images/casino/banner_casino-half.webp'
import roulletee from '../images/casino/ezugi.webp'
import venuscasino from '../images/casino/venus.webp'
import Colorgame from '../images/casino/colorgame.jpeg'
import card32 from '../images/kv/32card-half.png'
import teenipatti from '../images/casino/teenpatti20.webp';
import cockfight from '../images/casino/cockfight.webp'
import updown from '../images/casino/7up7down.jpeg'
import Evolution from '../images/casino/banner_evo-half.webp'
import sportse from '../images/casino/sportse.webp'
import anderbahar from '../images/casino/andarbahar.webp'
import CoinToss from '../images/kv/CoinToss.png'
import blackjack from '../images/casino/Blackjack.webp'
import numbermatka from '../images/kv/numberMatka.png'
import Sports from '../images/Banner/bannersports.webp'
import cardmatka from '../images/kv/cardMatka.png'
import mineswiper from '../images/casino/mineswiper.jpeg'
// import mojosseven from '../images/casino/venus-01.png'
// import Support from './Support';
import sibco from '../images/casino/sicbokm.jpeg'
import SABA from '../images/casino/saba-sport.webp'
import KingMakers from '../images/casino/lobby_kingmakers.ad101fd6.webp'
import ludogame from '../images/casino/LUDO.webp'
import aeSexyImage from '../images/casino/aesexy.webp'
import roulette from '../images/casino/europianroullete.webp'
import cardhilo from '../images/casino/cardhillo.webp'
import NumberKing from '../images/casino/numberking.webp'
import JilI from '../images/casino/banner_JILI-full.webp'
import BigSmall from '../images/casino/big-small.webp'
import Teenpattijoker from '../images/casino/teenpatti.jpeg'
import updownseven from '../images/casino/7updown.webp'
import DragerTiger from '../images/casino/dragon-tiger.jpeg'
import CallBreak from '../images/casino/callbreak.webp'
import SicboJili from '../images/casino/sicbo.webp'
import BaccaratJili from '../images/casino/baccaret.webp'
import horserace from '../images/Banner/horse-race.webp'
import pragmatics from '../images/casino/pp.jpeg'


import CasinoCard from '../mobilecomponent/components/casino-card/casino-card';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const photos = [
    // {
    //     name: 'photo 1',
    //     url: [IPL]
    // },
    // {
    //     name: 'photo 2',
    //     url: [IPL2]
    // },
    // {
    //     name: 'photo 3',
    //     url: [Womnesbanner]
    // },
    // {
    //     name: 'photo 7',
    //     url: [Matka]
    // },
    // {
    //     name: 'photo 8',
    //     url: [NumberMatka]
    // },
    // {
    //     name: 'photo 9',
    //     url: [andarbahar]
    // }


]

const settings = {
    dots: true,
    slide: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 800,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    className: 'slidesN'
}

export default function Bannerpage(props) {
    const [Socialicon, setSocialicon] = useState(1);
    const [countCricket, setCountCricket] = useState(0);
    const [countSoccer, setCountSoccer] = useState(0);
    const [countTennis, setCountTennis] = useState(0);
    const [casinoWait, setcasinoWait] = useState(false);
    const [getBanners, setBanners] = useState([])

    // const history = useHistory();

    const history = {
        push: (url) => window.location.href = url
    }

    function changecount(cri, socc, tenn) {

        setCountCricket(cri);
        setCountSoccer(socc);
        setCountTennis(tenn);
    }

    // Fetch Sites Banner
    const getBannerSiteImages = async () => {
        try {
            let resp = await axios.post(
                'https://liveapi247.live/api8/getBanner',
                {
                    siteName: '9XPLAYADMIN'
                }
            );
            if (resp) {
                // cosnsole.log(resp.data)
                if (resp.status == 200) {
                    return resp.data;
                }
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    useEffect(async () => {
        setBanners(photos);
        let val = await getBannerSiteImages();
        if (val.length > 0) {
            let newResp = [{ url: val[0].banner1 }, { url: val[0].banner2 }, { url: val[0].banner3 }, { url: val[0].banner4 }, { url: val[0].banner5 }, { url: val[0].banner6 }, { url: val[0].banner7 }, { url: val[0].banner8 }, { url: val[0].banner9 }, { url: val[0].banner10 }]
            setBanners(newResp)
        }
    }, []);


    useEffect(() => {
        axios.all([
            axios.get('https://liveapi247.live/api8/cricketData'),
            axios.get('https://liveapi247.live/api8/soccerData'),
            axios.get('https://liveapi247.live/api8/tennisData')
        ])
            .then(axios.spread((obj1, obj2, obj3) => {


                var count1 = 0;
                var count2 = 0;
                var count3 = 0;

                obj1.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })

                obj2.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })
                obj3.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })

                obj1.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count1 = count1 + 1;
                    }
                });
                obj2.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count2 = count2 + 1;
                    }
                });
                obj3.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count3 = count3 + 1;
                    }
                });
                changecount(count1, count2, count3);
            }));
    }, [])

    return (
        <React.Fragment>

            {casinoWait === true &&
                <ul class="loading">
                    <li><img src={Loading} /></li>
                    <li>Loading...
                    </li>
                    <br />
                    <li><span id="progress"></span></li>
                </ul>

            }
            {/* { casinoWait && <div class="bl-Preloader" id="__-plContainer">
         <div class="bl-Preloader_Header">
           <div class="bl-Preloader_ProductHeader "></div>
               <div class="bl-Preloader_MainHeader ">
            <div class="bl-Preloader_MainHeaderLogo "></div>
           </div>
          </div>
         <div class="bl-Preloader_SpinnerContainer ">
           <div class="bl-Preloader_Spinner "></div>
         </div>
       </div> } */}

            {/* <div class="bl-Preloader" id="__-plContainer">
    <div class="bl-Preloader_Header">
        <div class="bl-Preloader_ProductHeader "></div>
        <div class="bl-Preloader_MainHeader ">
            <div class="bl-Preloader_MainHeaderLogo "></div>
        </div>
    </div>
    <div class="bl-Preloader_SpinnerContainer ">
        <div class="bl-Preloader_Spinner "></div>
    </div>
</div> */}
            {/* <div class="overlay" style={{display: "flex",justifyContent:'center',alignItems:'center',backgroundColor:'rgb(0 0 0 / 90%)'}}>
                            <div id="page-wrap">
                    <div class="meter red">
                        <span style={{width: "100%"}}></span>
                    </div>
                        </div>
                        </div> */}


            <div _ngcontent-lst-c68="" id="centerColumn" class="centar-content casinozone">

                <div _ngcontent-lst-c68="" id="overWrap" class="lit-content-center" style={{ height: "calc(100% - 0px)" }}>
                    <div _ngcontent-lst-c68="" class="images-grid">
                        <Slider  {...settings}>
                            {getBanners.filter((x, i) => x.url !== null).map((photo, id) => {
                                return (
                                    <div _ngcontent-njs-c67="" key={id}>
                                        <img _ngcontent-njs-c67="" src={photo.url} alt="cric" style={{ height: '192px' }} />
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                    <div _ngcontent-lst-c68="" class="casinozone-wrap-simple">
                        <a _ngcontent-lst-c68="" href="/" style={{ cursor: "pointer" }}>
                            <dl _ngcontent-lst-c68="" id="onLiveBoard" class="live_events">
                                <dt _ngcontent-lst-c68="" >
                                    <p _ngcontent-lst-c68="" class="live_icon"><span></span> LIVE</p>
                                </dt>

                                <dd _ngcontent-lst-c68="" id="onLiveCount_CRICKET">
                                    <p _ngcontent-lst-c68="" >Cricket</p><span id="count">{countCricket}</span></dd>

                                <dd _ngcontent-lst-c68="" id="onLiveCount_SOCCER">
                                    <p _ngcontent-lst-c68="" >Soccer</p><span id="count">{countSoccer}</span></dd>

                                <dd _ngcontent-lst-c68="" id="onLiveCount_TENNIS">
                                    <p _ngcontent-lst-c68="" >Tennis</p><span id="count">{countTennis}</span></dd>

                            </dl>
                            <dl _ngcontent-lst-c68="" class="casino-title">
                                <dt _ngcontent-lst-c68="" >Sports</dt>
                                <dd _ngcontent-lst-c68="" >Play Now</dd>
                            </dl>
                            <img _ngcontent-lst-c68="" src={Sports} alt="" draggable="false" />
                        </a>


                        <CasinoCard
                            isLoggedIn={props.isLoggedIn}
                            navKey="SABA"
                            title="Saba"
                            img={SABA}
                            openLogin={props.openLogin}
                            history={history}
                        />
                        <CasinoCard
                            isLoggedIn={props.isLoggedIn}
                            navKey="Sexy"
                            title="Sexy"
                            img={aeSexyImage}
                            openLogin={props.openLogin}
                            history={history}
                        />
                        <CasinoCard
                            isLoggedIn={props.isLoggedIn}
                            navKey="LUDO"
                            title="Ludo"
                            img={ludogame}
                            openLogin={props.openLogin}
                            history={history}
                        />

                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="Live Casino"
                            title="Live Casino"
                            img={livecasino}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />
                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="VENUS"
                            title="Venus"
                            img={venuscasino}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />

                        <CasinoCard
                            isLoggedIn={props.isLoggedIn}
                            navKey="E1SPORT"
                            title="E1SPORT"
                            img={sportse}
                            openLogin={props.openLogin}
                            history={history}
                        />
                        <CasinoCard
                            isLoggedIn={props.isLoggedIn}
                            navKey="KINGMIDAS"
                            title="KINGMIDAS"
                            img={KingMakers}
                            openLogin={props.openLogin}
                            history={history}
                        />
                        <CasinoCard
                            isLoggedIn={props.isLoggedIn}
                            navKey="JILI"
                            title="Jili"
                            img={JilI}
                            openLogin={props.openLogin}
                            history={history}
                        />
                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="sicbo"
                            title="SicBo"
                            img={sibco}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />

                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="7 Up 7 Down"
                            title="7 up 7 down"
                            img={updown}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />

                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="Coin Toss"
                            title="Coin Toss"
                            img={CoinToss}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />

                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="NumberMatka"
                            title="Number Matka"
                            img={numbermatka}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />
                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="CardMatka"
                            title="Card Matka"
                            img={cardmatka}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />

                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="Andar Bahar"
                            title="Andar Bahar"
                            img={anderbahar}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />

                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="Blackjack"
                            title="Blackjack"
                            img={blackjack}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />

                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            title="Ezugi Casino"
                            img={roulletee}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />

                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="pragmatics play"
                            title="Pregmatics Play"
                            img={pragmatics}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />
                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="Cards Hi Lo"
                            title="Card Hilo"
                            img={cardhilo}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />
                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="Minesweeper"
                            title="Mine Sweeper"
                            img={mineswiper}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />

                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="Teen Patti"
                            title="TeenPatti"
                            img={teenipatti}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />


                        {/* <!-- BetGames --> */}
                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="NumberKing"
                            title="Number King"
                            img={NumberKing}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />
                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="Big small"
                            title="Big Small"
                            img={BigSmall}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />
                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="TeenPatti Joker"
                            title="Teenpatti Joker"
                            img={Teenpattijoker}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />
                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="7up7down"
                            title="7Up7Down"
                            img={updownseven}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />
                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="Dragon & Tiger"
                            title="Dragon & Tiger"
                            img={DragerTiger}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />

                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="Callbreak Quick"
                            title="Callbreak Quick"
                            img={CallBreak}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />
                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="Sic Bo"
                            title="Sic Bo"
                            img={SicboJili}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />
                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="Baccarat"
                            title="Bacarrat"
                            img={BaccaratJili}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />
                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="32 Cards"
                            title="32 Cards"
                            img={card32}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />

                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="Colour Game"
                            title="Color Game"
                            img={Colorgame}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />
                        <CasinoCard
                            className="mob-casino-half"
                            isLoggedIn={props.isLoggedIn}
                            navKey="European Roulette"
                            title="european roulette"
                            img={roulette}
                            openLogin={props.openLogin}
                            history={history}
                            style={{}}
                        />

                        <CasinoCard
                            isLoggedIn={props.isLoggedIn}
                            navKey="HORSE BOOK"
                            title="Horse Racing"
                            img={horserace}
                            openLogin={props.openLogin}
                            history={history}
                        />

                        <CasinoCard
                            isLoggedIn={props.isLoggedIn}
                            navKey="Cock Fight PHI"
                            title="Cockfight"
                            img={cockfight}
                            openLogin={props.openLogin}
                            history={history}
                        />


                    </div>

                    <div _ngcontent-lst-c68="" class="footer" >


                        <div id="supportWrap" className="support-wrap">
                            <div className="support-service">
                                <a id="support_email" onMouseOver={() => { setSocialicon(0) }} className={`support-mail  ${(Socialicon === 0) ? "open" : "null"}`} >
                                    <img src={Transparent} title="Email" /></a>
                                <a id="support_whatsapp" onMouseOver={() => { setSocialicon(1) }} className={`support-whatsapp  ${(Socialicon === 1) ? "open" : "null"}`} >
                                    <img src={Transparent} title="WhatsApp" /></a>
                                <a
                                    id="support_telegram" onMouseOver={() => { setSocialicon(2) }} className={`support-telegram  ${(Socialicon === 2) ? "open" : "null"}`} >
                                    <img src={Transparent} title="Telegram" /></a>
                                <a id="support_email" onMouseOver={() => { setSocialicon(3) }} className={`support-skype  ${(Socialicon === 3) ? "open" : "null"}`} >
                                    <img src={Transparent} title="skype" /></a>
                                <a
                                    id="support_email" onMouseOver={() => { setSocialicon(4) }} className={`support-ig  ${(Socialicon === 4) ? "open" : "null"}`} >
                                    <img src={Transparent} title="skype" /></a>
                            </div>
                            <div className="support-info">
                                <div id="supportDetail_telegram" className="support-detail open">
                                    {Socialicon === 1 && <a className="ui-link" href="+971524225063">
                                        <span style={{ color: 'rgb(102, 102, 102)', textDecoration: 'underline' }} />0000</a>}
                                    {Socialicon === 2 && <a className="ui-link" href="https://t.me/fair247_bet">
                                        <span style={{ color: 'rgb(102, 102, 102)', textDecoration: 'underline' }} />fair247_bet</a>}
                                </div>
                            </div>
                        </div>

                        <div _ngcontent-lst-c67="" class="power-content" style={{ width: "109px" }}>
                            <h3 _ngcontent-lst-c68="" style={{ backgroundImage: 'none', lineHeight: '15px', color: '#484745', marginTop: '-5px', textAlign: 'left' }}><span>Powered by</span><img src={Transparent} alt="poweredby" /></h3>
                        </div>
                        <div _ngcontent-lst-c68="" class="browser-footer-content">

                            <img _ngcontent-lst-c68="" src={Transparent} alt="gif" />
                            <br></br>
                            Our website works best in the newest and last prior version of these browsers: <br></br>Google Chrome.
                        </div>


                        <ul _ngcontent-lst-c68="" className="mob-inform-link">
                            <li _ngcontent-lst-c68=""  ><a >KYC</a></li>
                            <li _ngcontent-lst-c68=""  ><a >About Us</a></li>
                            <li _ngcontent-lst-c68=""  ><a >Privacy Policy</a></li>
                            <li _ngcontent-lst-c68=""  ><a >Responsible Gaming</a></li>
                            <li _ngcontent-lst-c68=""  ><a >Terms and Conditions</a></li>
                            <li _ngcontent-lst-c68=""  ><a >Underage Gaming Policy</a></li>
                            <li _ngcontent-lst-c68=""  ><a >Self-Exclusion Policy</a></li>
                            <li _ngcontent-lst-c68=""  ><a >Rules and Regulations</a></li>









                        </ul>


                        <ul _ngcontent-lst-c68="" class="RnR-link">
                            <li _ngcontent-lst-c68=""><a >Rules and Regulations</a></li>
                        </ul>
                    </div>
                </div>

            </div>

        </React.Fragment>
    )
}
