import React, { useEffect, useState } from 'react'
import Cup from '../images/sprite/cup.gif';
import CricketMatches from './CricketMatches';
import SearchBar from './SearchBar';
import SoccerMatches from './SoccerMatches';
import TennisMatches from './TennisMatches';
import axios from 'axios';
import "./fair247.css"
import moment from 'moment'
import Kabaddi from './Kabaddi';
import Support from './Support';
import Binary from './Binary';
import Transparent from '../images/sprite/transparent.gif'
import { Link } from 'react-router-dom';
// import horse from '../images/horsel.6a5281de.svg'
// import MT5 from '../images/mt5.4f09b4e8.svg'

import Cookies from 'universal-cookie';
import BasketballMatches from './BasketballMatches';
import Election from './Election';

const cookies = new Cookies();

require('moment-timezone');

export default function Highlights(props) {
	const [highlightTab, sethighlightTab] = useState(4)
	const [Searchbar, setSearchbar] = useState(false)
	const [countCricket, setCountCricket] = useState(0);
	const [countSoccer, setCountSoccer] = useState(0);
	const [CountBasketball, setCountBasketball] = useState(0);
	const [countTennis, setCountTennis] = useState(0);
	const [newrefresh, setnewrefresh] = useState(true);
	const [casinoWait, setcasinoWait] = useState(false);


	function changecount(cri, socc, tenn, BasBall) {

		setCountCricket(cri);
		setCountSoccer(socc);
		setCountTennis(tenn);
		setCountBasketball(BasBall);
	}

	useEffect(() => {
		axios.all([
			axios.get('https://liveapi247.live/api1/cricketData'),
			axios.get('https://liveapi247.live/api1/soccerData'),
			axios.get('https://liveapi247.live/api1/tennisData'),
			axios.get('https://liveapi247.live/api1/BasketballData')
		])
			.then(axios.spread((obj1, obj2, obj3, obj4) => {

				setnewrefresh(false)
				var count1 = 0;
				var count2 = 0;
				var count3 = 0;
				var count4 = 0;

				obj1.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})

				obj2.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})
				obj3.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})

				obj4.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})

				obj1.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count1 = count1 + 1
					}
				});
				obj2.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count2 = count2 + 1;
					}
				});
				obj3.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count3 = count3 + 1;
					}
				});

				obj4.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count4 = count4 + 1;
					}
				});


				changecount(count1, count2, count3, count4);



			}));
	}, [])

	const openSearchbar = () => {
		setSearchbar(true)
	}

	const closeSearchbar = () => {
		setSearchbar(false)
	}


	const openAESexy = (gameI) => {
		if (!props.isLoggedIn) {
			props.checkShowLogin(true);
			return;
		}
		else {
			var utoken = cookies.get('token');
			var user = cookies.get('id');
			var mil = Date.now();

			if (!user || !utoken) {
				return;
			}
			setnewrefresh(true);
			axios.post('https://liveapi247.live/api/gapcasino/game/url', {
				user: user,
				token: utoken,
				platform: 'GPL_DESKTOP',
				lobby_url: window.location.href,
				game_id: gameI

			})
				.then(result => {

					setTimeout(() => { setnewrefresh(false); }, 4000);
					if (result.data.url) {
						// window.open(result.data.url);
						//var newTab=window.open('about:blank');
						window.location.href = result.data.url;

					}


				})
				.catch(e => {

				});

		}
	}





	return (

		<React.Fragment>
			{/* {newrefresh === true &&
				<div  className="loading-overlay" id="loading">
					<div  className="loading-wrap" style={{ display: " flex" }}>
						<div  className="loading">
							<div></div>
							<div></div>
						</div>
						<p>Loading...</p>
					</div>
				</div>} */}
		{newrefresh === true &&	<div class="loading-overlay" style={{display: 'flex'}} id="loader__">
	<div class="loading-wrap" style={{display: 'flex'}}>
		<div class="loading">
			<div></div>
			<div></div>
		</div>
		<p>Loading...</p>
	</div>
</div>}

			{newrefresh === false &&
				<div  className='mob-main-content mian-wrap'  >
					{Searchbar === true && <SearchBar closeSearchbar={closeSearchbar} />}
					{/* <div  id="highlightLabel" className="highlight-fix">

						<a className="a-search" onClick={openSearchbar} >Search</a>
						<div  id="frame" className="mob-nav-highlight ps ps--theme_default ps--active-x">


							<ul  id="label">
								{props.isLoggedIn &&
									<li  className="menu-casino">
										<span  className="tag-new">New</span>
										<Link to="/CasinoList" id="casinoLoginBtn" ><img className="icon-casino" src={Transparent} />Casino</Link>
									</li>}

							

								<li  id="highlightTab4" onClick={() => { sethighlightTab(4) }} className={`${(highlightTab === 4) ? "select" : "null"}`}>
									<span  id="tagLive" className="mob-live-number"><strong></strong>{countCricket}</span>
									<a  ><img  className="icon-cricket" src={Cup} alt='gif' />Cricket</a>
								</li>

								

								<li  id="highlightTab1" onClick={() => { sethighlightTab(1) }} className={`${(highlightTab === 1) ? "select" : "null"}`}>
									<span  id="tagLive" className="mob-live-number"><strong></strong>{countSoccer}</span>
									<a  ><img  className="icon-soccer" src={Cup} alt='gif' />Soccer</a>
								</li>
							


							

								<li  id="highlightTab2" onClick={() => { sethighlightTab(2) }} className={`${(highlightTab === 2) ? "select" : "null"}`}>
									<span  id="tagLive" className="mob-live-number"><strong></strong>{countTennis}</span>
									<a  ><img  className="icon-tennis" src={Cup} alt='gif' />Tennis</a>
								</li>

							


								<li  id="highlightTab3" onClick={() => { sethighlightTab(138) }} className={`${(highlightTab === 138) ? "select" : "null"}`}>
									<a  ><img  className="icon-kabaddi" src={Transparent} />Kabaddi</a>
								</li>
							
							</ul>

							<div  className="ps__scrollbar-x-rail"><div  className="ps__scrollbar-x"  ></div></div><div  className="ps__scrollbar-y-rail" ><div  className="ps__scrollbar-y"  ></div></div></div>
					</div> */}

<div id="highlightLabel" className="highlight-fix">
  {" "}
  <a
    className="a-search"
    href="#"
    onclick="document.getElementById('searchWrap').style.display='block'"
  >
    Search
  </a>
  <div
    id="frame"
    className="wrap-highlight ps ps--theme_default ps--active-x"
    data-ps-id="5abd3a57-52c9-1ecb-e745-bdf271a1a2c5"
    style={{ overflowX: "scroll" }}
  >
    {" "}
   
    <ul id="label" style={{ left: 0 }}>
      <li className="menu-casino">
        <span className="tag-new">New</span>
        <a id="casinoLoginBtn" className="bg_color-2" href="/casino">
          <img
            className="icon-casino"
            src="https://d1gvwx1uptx1i3.cloudfront.net/pi/exchange/images/transparent.gif"
          />
        </a>
        <span>Casino</span>
      </li>
      <li id="highlightTab4" lassName={`${(highlightTab === 4) ? "highlightCls select" : "highlightCls"}`}>
        <span id="tagLive" className="tag-live">
          <strong />{countCricket}
        </span>
        <a href="#"  onClick={() => { sethighlightTab(4) }} className={`${(highlightTab === 4) ? "bg_color-3 border_active" : "bg_color-3"}`}>
          <img
            className=""
            src="https://d1gvwx1uptx1i3.cloudfront.net/pi/exchange/images/stump.svg"
          />
        </a>
        <span>Cricket</span>
      </li>
      <li id="highlightTab1" className={`${(highlightTab === 1) ? "highlightCls select" : "highlightCls"}`} >
        <span id="tagLive" className="tag-live">
          <strong />
          {countSoccer}
        </span>
        <a href="#" onClick={() => { sethighlightTab(1) }} className={`${(highlightTab === 1) ? "bg_color-4 border_active" : "bg_color-4"}`} >
          <img
            className=""
            src="https://d1gvwx1uptx1i3.cloudfront.net/pi/exchange/images/soccer.svg"
          />
        </a>
        <span>Soccer</span>
      </li>
      <li id="highlightTab2" lassName={`${(highlightTab === 2) ? "highlightCls select" : "highlightCls"}`} data-eventtype={2}>
        <span id="tagLive" className="tag-live">
          <strong />
          {countTennis}
        </span>
        <a href="#" onClick={() => { sethighlightTab(2) }} className={`${(highlightTab === 2) ? "bg_color-5 border_active" : "bg_color-5"}`} >
          <img
            className=""
            src="https://d1gvwx1uptx1i3.cloudfront.net/pi/exchange/images/tennis.svg"
          />
        </a>
        <span>Tennis</span>
      </li>
     
    </ul>
    <div
      className="ps__scrollbar-x-rail"
      style={{ width: 280, left: 0, bottom: 0 }}
    >
      <div
        className="ps__scrollbar-x"
        tabIndex={0}
        style={{ left: 0, width: 237 }}
      />
    </div>
    <div className="ps__scrollbar-y-rail" style={{ top: 0, right: 0 }}>
      <div
        className="ps__scrollbar-y"
        tabIndex={0}
        style={{ top: 0, height: 0 }}
      />
    </div>
  </div>
</div>


					<div  className="mob-nav-highlight_list" style={{ height: 'auto', backgroundColor: 'pink' }}  >
						<h3>  Highlights</h3>

						<ul  id="highlightList" className="highlight-list event_list event_1t">
							{highlightTab === 4 && <CricketMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 1 && <SoccerMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 2 && <TennisMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 7522 && <BasketballMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 2378962 && <Election isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState}/>}
							{highlightTab === 138 && <Kabaddi isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState}/>}
						</ul>
					</div>
					{/* <Support handlepolicy={props.handlepolicy} /> */}

				</div>}
		</React.Fragment>
	)
}
